:root {
    --font-family: 'Helvetica', sans-serif;
    --font-family-bold: 'Helvetica Bold', sans-serif;
    --font-size-1px: 0.0977vw;
    --font-size-2px: 0.1953vw;
    --font-size-3px: 0.2929vw;
    --font-size-4px: 0.3906vw;
    --font-size-5px: 0.4882vw;
    --font-size-6px: 0.5858vw;
    --font-size-7px: 0.6835vw;
    --font-size-8px: 0.7812vw;
    --font-size-9px: 0.8789vw;
    --font-size-10px: 0.9765vw;
    --font-size-11px: 1.0742vw;
    --font-size-12px: 1.1719vw;
    --font-size-13px: 1.2695vw;
    --font-size-14px: 1.3672vw;
    --font-size-15px: 1.4648vw;
    --font-size-16px: 1.5625vw;
    --font-size-17px: 1.6602vw;
    --font-size-18px: 1.7578vw;
    --font-size-19px: 1.8555vw;
    --font-size-20px: 1.9531vw;
    --font-size-21px: 2.0508vw;
    --font-size-22px: 2.1484vw;
    --font-size-23px: 2.2461vw;
    --font-size-24px: 2.3438vw;
    --font-size-25px: 2.4414vw;
    --font-size-26px: 2.5391vw;
    --font-size-27px: 2.6367vw;
    --font-size-28px: 2.7344vw;
    --font-size-29px: 2.8320vw;
    --font-size-30px: 2.9297vw;
    --font-size-31px: 3.0274vw;
    --font-size-32px: 3.1250vw;
    --font-size-33px: 3.2227vw;
    --font-size-34px: 3.3203vw;     
    --font-size-35px: 3.4180vw;
    --font-size-36px: 3.5156vw;
    --font-size-37px: 3.6133vw;
    --font-size-38px: 3.7109vw;
    --font-size-39px: 3.8086vw;
    --font-size-40px: 3.9062vw;
    --font-size-90px: 8.7890vw;
}
  
html, body {
    height: 100%;
    margin: 0;
    padding: 0;
    font-family: var(--font-family); 
}

body {
    font-family: var(--font-family); 
}

.cursor-pointer {
    cursor: pointer;
}

.main-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.main-content {
    flex: 1;
}

.main-wrapper {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.page-loader {
    margin: auto;
}

.row {
    margin: 0;
}